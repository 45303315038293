import React from "react";
import Slider from "react-slick";
import { formatImageUrl } from "../../../helper/helper";
import Button from "../common/CommonButton/Button";

const WhiskyCollectionCard = (props: any) => {
  let { data } = props;
  const bbCardItems = data?.bb_card_item || [];
  var settings = {
    speed: 1000,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    arrows: false,
  };
  return (
    <section className="whisky_collection_main_section">
      <div className="whisky_for_desktop">
        {bbCardItems.length > 0 &&
          bbCardItems.map((item: any, index: any) => {
            let bgImage: any = formatImageUrl(
              item?.background?.data?.attributes?.url
            );
            return (
              <div className="whisky_card" key={item?.id || index}>
                {item?.background_mobile?.data && (
                  <img src={bgImage} alt={`whisky img`} />
                )}

                {item?.cta?.link && (
                  <Button
                    btnText={item?.cta?.title}
                    btnLink={item?.cta?.link}
                    linkOpenState={item?.cta?.open_in_new_tab}
                    btnClass={"whisky_card_btn_for"}
                  />
                )}
              </div>
            );
          })}
      </div>

      <div className="whisky_for_mobile">
        <div className="whisky_card_slider">
          {bbCardItems.length > 0 && (
            <Slider {...settings}>
              {bbCardItems.map((item: any, index: number) => {
                let bgImage: any = formatImageUrl(
                  item?.background_mobile?.data?.attributes?.url
                );
                return (
                  <div className="whisky_card" key={item?.id || index}>
                    {item?.background_mobile?.data && (
                      <img src={bgImage} alt={`whisky img`} />
                    )}

                    {item?.cta?.link && (
                      <Button
                        btnText={item?.cta?.title}
                        btnLink={item?.cta?.link}
                        linkOpenState={item?.cta?.open_in_new_tab}
                        btnClass={"whisky_card_btn_for"}
                      />
                    )}
                  </div>
                );
              })}
            </Slider>
          )}
        </div>
      </div>
    </section>
  );
};

export default WhiskyCollectionCard;
