import React from "react";
import Button from "../common/CommonButton/Button";
import { formatImageUrl } from "../../../helper/helper";

const BlackBullBannerDetails = (props: any) => {
  const { data } = props;
  let bgImage: any = formatImageUrl(data?.background?.data?.attributes?.url);
  let bottle_image: any = formatImageUrl(
    data?.bottle_image?.data?.attributes?.url
  );
  let media: any = formatImageUrl(data?.media?.data?.attributes?.url);
  return (
    <section
      className="black_bull_new_details_section"
      style={{
        backgroundImage: `url(${bgImage})`,
      }}
    >
      <div className="inner_div">
        <div className="black_bull_content">
          <div className="black_bull_details">
            <h3>{data?.title}</h3>
            <h4>{data?.year_title}</h4>
            <p>{data?.content}</p>
            {/* <div className="read_more_btn_text_new">read more</div> */}
            {data?.cta?.link && (
              <Button
                btnText={data?.cta.title}
                btnLink={data?.cta.link}
                linkOpenState={data?.cta.open_in_new_tab}
                btnClass={"black_bull_learn_more_btn"}
              />
            )}
          </div>
        </div>
        <div className="black_bull_bottle_img_box">
          <img src={bottle_image} />
        </div>
        <div className="black_bull_banner_img">
          <img src={media} />
        </div>
      </div>
    </section>
  );
};

export default BlackBullBannerDetails;
