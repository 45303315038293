import Image from "next/image";
import React, { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { formatImageUrl } from "../../../helper/helper";

const NikFaldoDetails = (props: any) => {
  let { data } = props;
  let media_legend: any = formatImageUrl(
    data?.media_legend?.data?.attributes?.url
  );
  // let media_legend: any = formatImageUrl(
  //   `{screenWidth > 900 ? ${data?.media_legend?.data?.attributes?.url} : ${data?.media_legendmobile?.data?.attributes?.url}}`
  // );
  let left_brand: any = formatImageUrl(
    data?.left_brand?.data?.attributes?.url
  );
  let right_brand: any = formatImageUrl(
    data?.right_brand?.data?.attributes?.url
  );

  const [moreDescription, setMoreDescription]: any = useState(true)

  return (
    <section className="nikfaldo-details-main-section">
      <Row>
        <Col md={6} >
          <div className="nikfaldo_detail_inner_div">
            <div className="main_detail_div">
              <h4>{data?.header}</h4>
              <p className={`${moreDescription ? "less_text" : "more_text"}`}>{data?.content} </p>
              <span className="read_more_nic_btn" onClick={() => setMoreDescription(!moreDescription)}>
                Read {moreDescription ? "More" : "Less"}
              </span>

              <div className="black_bull_logos_section">

                <img
                  src={left_brand}
                  alt={`nik logo`}
                  className="black_bull_img"
                />

                <img
                  src="/new-blackbull-collection/close-icon.png"
                  alt={`corse icon`}
                  className="close_icon"
                />

                <img
                  src={right_brand}
                  alt={`nic sign`}
                  className="nick_sign_img"
                />
              </div>
            </div>
          </div>
          {/* <div className="inner_logo_div">
            <div className="main_ogo_div">
              {data?.legend_image?.data && (
                <img
                  src={legend_image}
                  alt={`nik logo`}
                  width={287}
                  height={233}
                />
              )}
            </div>
          </div> */}
        </Col>
        <Col md={6} >
          <div className="nikfaldo_img_section">
            {data?.media_legend?.data && (
              <img
                src={media_legend}
                alt={`nikfaldo main img`}
                width={775}
                height={555}
                className="nikfaldo_main_img"
              />
            )}
          </div>
        </Col>
        {/* <Col sm={3}>
          <div className="nikfaldo_detail_inner_div">
            <div className="main_detail_div">
              <h4>{data?.header}</h4>
              <p>{data?.content}</p>
            </div>
          </div>
        </Col> */}
      </Row>

      {/* <Row>
        <Col sm={3} className="second_mobile_order">
          <div className="inner_logo_div">
            <div className="main_ogo_div">
              {data?.legend_image?.data && (
                <Image
                  src={legend_image}
                  alt={`nik logo`}
                  width={287}
                  height={233}
                />
              )}
            </div>
          </div>
        </Col>
        <Col sm={6} className="first_mobile_first">
          {data?.center_image?.data && (
            <Image
              src={centerImage}
              alt={`nikfaldo main img`}
              width={775}
              height={555}
            />
          )}
        </Col>
        <Col sm={3} className="third_mobile_third">
          <div className="nikfaldo_detail_inner_div">
            <div className="main_detail_div">
              <h4>{data?.header}</h4>
              <p>{data?.content}</p>
            </div>
          </div>
        </Col>
      </Row> */}
    </section>
  );
};

export default NikFaldoDetails;
