import Image from "next/image";
import React from "react";
import Slider from "react-slick";
import { formatImageUrl } from "../../../helper/helper";
import Button from "../common/CommonButton/Button";
import Link from "next/link";

const RangeDetails = (props: any) => {
  const { data } = props;

  const screenWidth = window.innerWidth;
  let bgImage: any = screenWidth > 767 ? formatImageUrl(data?.background?.data?.attributes?.url) : formatImageUrl(data?.background_mobile?.data?.attributes?.url);

  let kyloeLogo: any = formatImageUrl(data?.media?.data?.attributes?.url);

  var settings = {
    speed: 1000,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    arrows: false,
  };

  var settingsDesktop = {
    speed: 1000,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        infinite: true,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section className="range_details_section">
      <div className="top_bar_text">{data?.header}</div>
      <div
        className="kyloe_whisky_type_section"
        style={{
          backgroundImage: `url(${bgImage})`,
        }}
      >
        <div className="for_black_shadow">
          <div className="kyloe_details">
            <div className="kyloe_img_with_text_div">
              {data?.media?.data && (
                <img
                  src={kyloeLogo}
                  alt={`logo`}
                  width={266}
                  height={183}
                  className="kylo_main_logo"
                />
              )}

              <p>{data?.content}</p>
            </div>

            {data?.items?.length > 0 && (
              <div className="kyloe_card_section_slider">
                <Slider {...settingsDesktop}>
                  {data?.items?.map((item: any, index: number) => {
                    let item_cardbg: any = formatImageUrl(
                      item?.background?.data?.attributes?.url
                    );
                    let item_cardbgMobile: any = formatImageUrl(
                      item?.background_mobile?.data?.attributes?.url
                    );
                 
                    let kyloe_logo: any = formatImageUrl(
                      item?.sticker?.data?.attributes?.url
                    );
                 

                    return (
                      <div className="kyloe_card" key={index}>
                        {item?.background?.data && (
                          <img
                            src={item_cardbg}
                            alt={`kylo card img`}
                            className="kylo_card_img for_desktop"
                          />
                        )}

                        {item?.background?.data && (
                          <img
                            src={item_cardbgMobile}
                            alt={`kylo card img`}
                            className="kylo_card_img for_mobile"
                          />
                        )}

                        <div className="kylo_btn_logo_section">
                          <div className="top_section">
                            <img
                                src={kyloe_logo}
                                alt={`kylo card img`}
                                className="kylo_logo_in_card"
                              />
                            <h2>{item?.name}</h2>
                          </div>

                          <div className="bottom_section">
                            {item?.cta?.link && (
                              <Button
                                btnText={item?.cta.title}
                                btnLink={item?.cta.link}
                                linkOpenState={item?.cta.open_in_new_tab}
                                btnClass={"whisky_card_btn_new"}
                              />
                            )}

                          </div>
                        </div>

                      </div>
                    );
                  })}
                </Slider>
              </div>
            )}

            {/* <div className="kyloe_card_section for_desktop">
              {data?.items?.length > 0 &&
                data?.items?.map((item: any, index: number) => {
                  let item_cardbg: any = formatImageUrl(
                    item?.background?.data?.attributes?.url
                  );
                  return (
                    <div className="kyloe_card" key={index}>
                      <Link
                        href={item?.cta?.link ? item?.cta?.link : "/"}
                        target={item?.cta?.open_in_new_tab ? "_black" : "_self"}
                      >
                        {item?.background?.data && (
                          <Image
                            src={item_cardbg}
                            alt={`kylo card img`}
                            width={255}
                            height={310}
                            className="kylo_card_img"
                          />
                        )}

                        <h2>{item?.name}</h2>
                      </Link>
                    </div>
                  );
                })}
            </div> */}
            {/* {data?.items?.length > 0 && (
              <div className="kyloe_card_section for_mobile">
                <Slider {...settings}>
                  {data?.items?.map((item: any, index: number) => {
                    let item_cardbg: any = formatImageUrl(
                      item?.background?.data?.attributes?.url
                    );

                    return (
                      <div className="kyloe_card" key={index}>
                        <Link
                          href={
                            item?.cta?.link !== null ? item?.cta?.link : "/"
                          }
                          target={
                            item?.cta?.open_in_new_tab ? "_black" : "_self"
                          }
                        >
                          {item?.background?.data && (
                            <Image
                              src={item_cardbg}
                              alt={`kylo card img`}
                              width={255}
                              height={310}
                              className="kylo_card_img"
                            />
                          )}
                          <h2>{item?.name}</h2>
                        </Link>
                      </div>
                    );
                  })}
                </Slider>
              </div>
            )} */}
          </div>
        </div>
      </div>
      {/* {data?.blackbull_12_background?.data && (
        <Image
          src={bb12Bacground}
          alt={`blackbull 12 banner`}
          width={1512}
          height={600}
          className="whisky_banner_desktop"
        />
      )} */}

      {/* {data?.blackbull_12_cta_link && (
        <Button
          btnText={data?.blackbull_12_cta_title}
          btnLink={data?.blackbull_12_cta_link}
          linkOpenState={data?.blackbull_12_cta_open_in_new_tab}
          btnClass={"whisky_card_btn"}
        />
      )} */}
      {/* {data?.blackbull_12_background_mobile?.data && (
        <Image
          src={bb12BackgroundMobile}
          alt={`blackbull 12 banner`}
          width={327}
          height={740}
          className="whisky_banner_mobile"
        />
      )} */}
    </section>
  );
};

export default RangeDetails;
