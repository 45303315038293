"use client";

import MainLayout from "@/components/layout/mainLayout";
import { useLocalsListStore } from "@/zustand/localesList";

import AboutTheCollection from "@/components/blackbullnewcollection/AboutTheCollection";
import BlackBullRacing from "@/components/blackbullnewcollection/BlackBullRacing";
import NikFaldoDetails from "@/components/blackbullnewcollection/NikFaldoDetails";
import SocialMediaPresentSlider from "@/components/blackbullnewcollection/SocialMediaPresentSlider";
import WhiskyCollectionCard from "@/components/blackbullnewcollection/WhiskyCollectionCard";
import RangeDetails from "@/components/blackbullnewcollection/rangeDetails";
import BuyNowCart from "@/components/common/BuyNowCart";
import BannerVideo from "@/components/common/video";
import SignupForFree from "@/components/landingpage/SignupForFree";
import ScoMeta from "@/components/scometa";
import {
  getCheckoutLineItems,
  setRemoveLineItemInCart,
  setUpdateQuantityInCart,
} from "@/util/utils";
import { useEffect, useState } from "react";
import NewCollectionBanner from "@/components/newoctavecollection/NewCollectionBanner";
import BlackBullBannerDetails from "@/components/blackbullnewcollection/BlackBullBannerDetails";
import OctaveDetailsSection from "@/components/newoctavecollection/OctaveDetailsSection";
import CaskDetails from "@/components/newoctavecollection/CaskDetails";

const BlackbullNewCollection = (props: any) => {
  const { data, meta, countryCode } = props;

  const [newBlackbullCollectionData, setNewBlackbullCollectionData]: any =
    useState(null);
  const [checkout, setCheckout] = useState({ checkout: { lineItems: [] } });
  const [openModal, setOpenModal]: any = useState(false);

  useEffect(() => {
    if (data) {
      setNewBlackbullCollectionData(data?.data?.attributes?.blocks);
    }
  }, [data]);

  const toggleCartSidebar = () => {
    getCheckoutLineItems(countryCode).then((res) => {
      setCheckout({
        ...res,
      });
    });

    setOpenModal(!openModal);
  };

  useEffect(() => {
    getCheckoutLineItems(countryCode).then((res) => {
      setCheckout({
        ...res,
      });
    });
  }, []);

  const updateQuantityInCart = (lineItemId: any, quantity: any) => {
    const lineItemsToUpdate = [
      { id: lineItemId, quantity: parseInt(quantity, 10) },
    ];
    setUpdateQuantityInCart(lineItemsToUpdate, countryCode).then((res) => {
      setCheckout({
        ...res,
      });
    });
  };

  const removeLineItemInCart = (lineItemId: any) => {
    setRemoveLineItemInCart(lineItemId, countryCode).then((res) => {
      setCheckout({
        ...res,
      });
    });
  };
  const renderNewBlackBullCollectionPage = () => {
    return (
      <>
        {newBlackbullCollectionData && newBlackbullCollectionData.length
          ? newBlackbullCollectionData.map((content: any, index: any) => {
              switch (content.__component) {
                case "sections.banner":
                  let videosrc = content.video_link;
                  return (
                    <div key={index}>
                      <NewCollectionBanner
                        data={content}
                        className="small_logo_for_banner top_paddings"
                      />
                    </div>
                  );

                case "octave.about-the-collection":
                  return (
                    // <CaskDetails key={content?.id + index} data={content} />
                    <OctaveDetailsSection
                      key={content?.id + index}
                      data={content}
                    />
                  );

                case "blended.range-collection":
                  return (
                    <div className="new_balckbull_collection_banner">
                      <RangeDetails key={content?.id + index} data={content} />
                    </div>
                  );
                case "blended.blackbull12":
                  return (
                    <div className="new_balckbull_collection_banner">
                      <BlackBullBannerDetails
                        key={content?.id + index}
                        data={content}
                      />
                    </div>
                  );

                case "blended.bb-card":
                  return (
                    <div className="new_balckbull_collection_banner cards_of_black_bull">
                      <WhiskyCollectionCard
                        key={content?.id + index}
                        data={content}
                      />
                    </div>
                  );

                case "blended.legends-card":
                  return (
                    <div className="new_balckbull_collection_banner">
                      <NikFaldoDetails
                        key={content?.id + index}
                        data={content}
                      />
                    </div>
                  );

                case "blended.bb-racing-banner":
                  return (
                    <BlackBullRacing data={content} key={content?.id + index} />
                  );
                // case "sections.social-media":
                //   return (
                //     <SocialMediaPresentSlider
                //       data={content}
                //       key={content?.id + index}
                //     />
                //   );

                default:
                  return null;
              }
            })
          : null}
      </>
    );
  };
  return (
    <>
      <BuyNowCart
        countryCode={countryCode}
        openModal={openModal}
        toggleCartSidebar={toggleCartSidebar}
        checkout={checkout}
        updateQuantityInCart={updateQuantityInCart}
        removeLineItemInCart={removeLineItemInCart}
      />
      <head>
        <ScoMeta meta={meta ? meta : {}} />
      </head>
      {newBlackbullCollectionData && (
        <MainLayout checkout={checkout} toggleCartSidebar={toggleCartSidebar}>
          <div className="blackbull-new-collection-main">
            {newBlackbullCollectionData && renderNewBlackBullCollectionPage()}
          </div>
          <SignupForFree />
        </MainLayout>
      )}
    </>
  );
};

export default BlackbullNewCollection;
