import React from "react";
import BannerVideo from "../common/video";
import Slider from "react-slick";
import { Image } from "react-bootstrap";
import { formatImageUrl } from "../../../helper/helper";

const BlackBullRacing = (props: any) => {
  let { data } = props;
  let racing_career_background: any = formatImageUrl(
    data?.racing_career_background?.data?.attributes?.url
  );
  let racing_career_background_mobile: any = formatImageUrl(
    data?.racing_career_background_mobile?.data?.attributes?.url
  );

  var settings = {
    speed: 2000,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    arrows: true,
    autoplay: true,
  };

  return (
    <section className="black_bull_racing_section">
      {/* <h2>{data?.title}</h2> */}
      {/* {data?.video_link && <BannerVideo srcURL={data?.video_link} />} */}

      <div className="racing_details_section">
        {/* <div className="details_section">
          <div className="sponsors_details">
            <div className="details_manage_div">
              <h2>{data?.header}</h2>
              <h3>{data?.sub_heading_top}</h3>
              <p>{data?.description_top}</p>

              <h4>{data?.heading_bottom}</h4>
              <h5>{data?.sub_heading_bottom}</h5>
              <h6>{data?.description_bottom}</h6>
            </div>
          </div>
        </div> */}

        <div className="car_slider_section">
          <div className="car_slider_content">
            <div className="car_slider_details">
              <h2>{data?.title}</h2>

              <h4>{data?.sub_title}</h4>

              <p>{data?.description}</p>
            </div>
          </div>

          {data?.items?.length > 0 && (
            <div>
              <Slider {...settings}>
                {data?.items.map((item: any, index: number) => {
                  let bgUrl: any = formatImageUrl(
                    item?.media?.data?.attributes?.url
                  );
                  return (
                    <div className="car_slider_inner_div" key={index}>
                      {item?.media?.data && (
                        <Image
                          src={bgUrl}
                          alt={`car images`}
                          width={756}
                          height={524}
                        />
                      )}
                    </div>
                  );
                })}
              </Slider>
            </div>
          )}
        </div>
      </div>

      <div className="racing_car_section">
        <div className="racing_career_details">
          <h2>{data?.british_title}</h2>
          <p>{data?.british_description}</p>
        </div>

        <div className="racing_career_banner">
          <img
            src={racing_career_background}
            alt="racing career banner"
            className="banner_img_car for_desktop"
          />
          <img
            src={racing_career_background_mobile}
            alt="racing career banner"
            className="banner_img_car for_mobile"
          />

          <div className="racing_content">
            <div className="career_data_section">
              <div className="prix_name">{data?.racing_carrer_list_title}</div>
              {data?.list_items &&
                data?.list_items?.length > 0 &&
                data?.list_items?.map((item: any, index: any) => {
                  return (
                    <div className="content_data" key={index}>
                      <h3>{item?.number}</h3>
                      <h4>{item?.name}</h4>
                    </div>
                  );
                })}
            </div>

            <div className="career_details for_desktop">
              <h5>{data?.racing_career_heading}</h5>

              <h6>{data?.british_description}</h6>
            </div>
          </div>

          <div className="career_details for_mobile">
            <h5>{data?.racing_career_heading}</h5>
            <h6>{data?.british_description}</h6>
          </div>
        </div>
      </div>

      {/* 
      <div className="racing_details_section background_bg">
        <div className="race_driver_image">
          {data?.racing_career_background?.data && (
            <Image
              src={carDriver}
              alt={`driver icon`}
              width={700}
              height={683}
              className="race_desktop"
            />
          )}
          {data?.racing_career_background_mobile?.data && (
            <Image
              src={carDriverMobile}
              alt={`driver icon`}
              width={393}
              height={242}
              className="race_mobile"
            />
          )}
        </div>

        <div className="racing_data_section">
          <div className="career_data">
            <h2>{data?.racing_career_heading}</h2>
            <p>{data?.racing_career_description}</p>

            <div className="total_data_section">
              <div className="title_of_data_section">
                {data?.racing_carrer_list_title}
              </div>
              {data?.list_items &&
                data?.list_items?.length > 0 &&
                data?.list_items?.map((item: any, index: any) => {
                  return (
                    <div className="card_of_data" key={index}>
                      <h3>{item?.number}</h3>
                      <h4>{item?.name}</h4>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div> */}
    </section>
  );
};

export default BlackBullRacing;
